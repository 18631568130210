(<template>
  <page-data-section :title="currentChart.name"
                     :progress="chartProgress"
                     class="charts-bar">
    <div class="charts-bar__total-wrapper">
      <span class="charts-bar__total-value">{{ totalAssignments }}</span>
      <span class="charts-bar__total-description">{{ totalAssignmentsDescription }}</span>
    </div>
    <div v-if="showCurrentCharts"
         class="charts-bar__charts-wrapper">
      <component :is="chartComponent.component"
                 v-for="chartComponent in currentChart.components"
                 :key="chartComponent.id"
                 :chart-data="chartComponent.chartData"
                 :options="chartComponent.options"
                 class="charts-bar__chart" />
    </div>
    <div v-else
         class="charts-bar__empty-chart">{{ $gettext('No assignments') }}</div>
  </page-data-section>
</template>)

<script>
  import {mapState} from 'vuex';
  import helpers from '@/helpers';
  import PageDataSection from '@/components/shared_components/page_data/PageDataSection';
  import LineChart from '@/components/statistics/charts/LineChart';
  import BarChart from '@/components/statistics/charts/BarChart';
  import PieChart from '@/components/statistics/charts/PieChart';
  import DoughnutChart from '@/components/statistics/charts/DoughnutChart';
  import HorizontalBarChart from '@/components/statistics/charts/HorizontalBarChart';

  const blueChartColour = '#246DFF';
  export default {
    asyncData({store, route}) {
      let promisesArr = '';
      const params = {
        chartId: route.params.chartId,
        scope: route.query.view
      };

      if (!store.state.StatisticsStore.statistics || route.query.view) {
        promisesArr = [...promisesArr, store.dispatch('StatisticsStore/fetchStatistics', params)];
      }

      const viewType = route.query.view === 'interpretation' ? 'Interpreters' : 'Translators';
      const bookedPerformers = store.state.StatisticsStore[`booked${viewType}`];

      if (!bookedPerformers || !bookedPerformers.length) {
        promisesArr.push(store.dispatch(`StatisticsStore/fetchBooked${viewType}`));
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'line-chart': LineChart,
      'bar-chart': BarChart,
      'horizontal-bar-chart': HorizontalBarChart,
      'pie-chart': PieChart,
      'doughnut-chart': DoughnutChart,
      'page-data-section': PageDataSection
    },
    data() {
      return {
        currentChart: '',
        colors: [blueChartColour, '#FAD623', '#54BF54', '#F5771D', ' #FF3624', '#104A81', '#ff0044', '#00cc77'],
      };
    },
    computed: {
      ...mapState('StatisticsStore', {
        chartProgress: (state) => state.chartProgress,
        statistics: (state) => state.statistics,
        startDate: ({filterValues}) => filterValues.startDate,
        endDate: ({filterValues}) => filterValues.endDate,
        departments: ({filterValues}) => filterValues.departments,
        interpreters: ({filterValues}) => filterValues.interpreters,
        bookers: ({filterValues}) => filterValues.bookers,
        languages: ({filterValues}) => filterValues.languages
      }),
      totalAssignments() {
        if (this.statistics.length === 0) return 0;

        const totalJobsCount = this.statistics.reduce((accumulator, currentValue) => {
          return accumulator + currentValue[`${this.dataType}CountTotal`];
        }, 0);

        return totalJobsCount;
      },
      isInterpretationView() { return this.$route.query.view === 'interpretation'; },
      dataType() { return this.isInterpretationView ? 'jobs' : 'subtasks'; },
      totalAssignmentsDescription() { return this.isInterpretationView ? this.$gettext('Assignments total') : this.$gettext('Projects total'); },
      currentChartId() { return this.$route.params.chartId || ''; },
      showCurrentCharts() { return this.currentChart?.components?.some((component) => component?.chartData?.datasets?.length); },
      charts() {
        if (this.statistics) {
          return [{
            id: 'line_department',
            name: this.isInterpretationView ? this.$gettext('Assignments per department') : this.$gettext('Projects per department'),
            components: this.getHorizontalBarChartComponents(this.statistics, !this.departments.length)
          }, {
            id: 'line_booker',
            name: this.isInterpretationView ? this.$gettext('Assignments per booker') : this.$gettext('Projects per booker'),
            components: this.getHorizontalBarChartComponents(this.statistics, !this.bookers.length)
          }, {
            id: 'line_interpreter',
            name: this.isInterpretationView ? this.$gettext('Assignments per interpreter') : this.$gettext('Projects per translator'),
            components: this.getHorizontalBarChartComponents(this.statistics, !this.interpreters.length)
          }, {
            id: 'line_status',
            name: this.isInterpretationView ? this.$gettext('Assignments per status') : this.$gettext('Projects per status'),
            components: this.getDoughnutChartComponents(this.statistics)
          }, {
            id: 'line_type',
            name: this.isInterpretationView ? this.$gettext('Assignments per type') : this.$gettext('Projects per type'),
            components: this.getDoughnutChartComponents(this.statistics)
          }, {
            id: 'line_language',
            name: this.isInterpretationView ? this.$gettext('Assignments per language') : this.$gettext('Projects per language pair'),
            components: this.getHorizontalBarChartComponents(this.statistics, !this.languages.length)
          }, {
            id: 'pie_type',
            name: this.isInterpretationView ? this.$gettext('Assignments per type by time period') : this.$gettext('Projects per type by time period'),
            components: this.getBarChartComponents(this.statistics)
          }, {
            id: 'bar_department',
            name: this.isInterpretationView ? this.$gettext('Assignments per department by time period') : this.$gettext('Projects per department by time period'),
            components: this.getBarChartComponents(this.statistics, !this.departments.length)
          }, {
            id: 'pie_booker',
            name: this.isInterpretationView ? this.$gettext('Assignments per booker by time period') : this.$gettext('Projects per booker by time period'),
            components: this.getBarChartComponents(this.statistics, !this.bookers.length)
          }, {
            id: 'pie_status',
            name: this.isInterpretationView ? this.$gettext('Assignments per status by time period') : this.$gettext('Projects per status by time period'),
            components: this.getBarChartComponents(this.statistics)
          }, {
            id: 'pie_interpreter',
            name: this.isInterpretationView ? this.$gettext('Assignments per interpreter by time period') : this.$gettext('Projects per translator by time period'),
            components: this.getBarChartComponents(this.statistics, !this.interpreters.length)
          }, {
            id: 'pie_language',
            name: this.isInterpretationView ? this.$gettext('Assignments per language by time period') : this.$gettext('Projects per language pair by time period'),
            components: this.getBarChartComponents(this.statistics, !this.languages.length)
          }];
        }
      }
    },
    watch: {
      currentChartId: {
        handler(val) {
          this.setCurrentChart(val);
        },
        immediate: true
      },
      dataType() {
        this.setCurrentChart(this.currentChartId);
      },
      statistics() {
        this.setCurrentChart(this.currentChartId);
      }
    },
    methods: {
      setCurrentChart(chartId) {
        this.currentChart = this.charts?.find((chart) => chart.id === chartId) || {};
      },
      getPieChartComponents(data = [], prepareDataForChart = false) {
        return [{
          id: Math.random(),
          component: 'pie-chart',
          options: {...this.getChartOptions(), ...{scales: {xAxes: [{display: false}], yAxes: [{display: false}]}}},
          chartData: this.getOtherChartData({
            data: prepareDataForChart ? this.prepareDataForChart([...data]) : data
          })
        }];
      },
      getDoughnutChartComponents(data = [], prepareDataForChart = false) {
        return [{
          id: Math.random(),
          component: 'doughnut-chart',
          plugins: {
            spacing: this.getCustomSpacing
          },
          options: {
            ...this.getChartOptions(),
            ...{
              legend: {
                position: 'right',
                labels: {
                  padding: 20,
                  boxWidth: 20,
                  fontStyle: 'bold',
                  fontSize: 14,
                  generateLabels: (chart) => {
                    const datasets = chart.data.datasets;

                    return datasets[0].data.map((data, i) => ({
                      text: `${chart.data.labels[i]} ${data}`,
                      fillStyle: datasets[0].backgroundColor[i],
                    }));
                  }
                }
              },
              cutoutPercentage: '75',
              scales: {xAxes: [{display: false}], yAxes: [{display: false}]}
            }
          },
          chartData: this.getOtherChartData({
            data: prepareDataForChart ? this.prepareDataForChart([...data]) : data
          })
        }];
      },
      getBarChartComponents(data = [], prepareDataForChart = false, xAxesLabel) {
        return [{
          id: Math.random(),
          component: 'bar-chart',
          options: {
            ...this.getChartOptions(xAxesLabel),
            ...{
              legend: {
                position: 'top'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }],
              },
            }
          },
          chartData: this.getTimeChartData({
            data: prepareDataForChart ? this.prepareDataForChart([...data], 3) : data
          })
        }];
      },
      getHorizontalBarChartComponents(data = [], prepareDataForChart = false) {
        return [{
          id: Math.random(),
          component: 'horizontal-bar-chart',
          options: {
            barThickness: 20,
            borderWidth: 1,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                ticks: {
                  beginAtZero: true
                }
              }]
            },
          },
          chartData: this.getBarChartData({
            data: prepareDataForChart ? this.prepareDataForChart([...data]) : data
          })
        }];
      },
      getChartOptions(xAxesLabel) {
        return {
          responsive: true,
          tooltips: {
            mode: 'index',
            intersect: false
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: xAxesLabel
              }
            }],
            yAxes: [{
              display: true,
              ticks: {
                stepSize: 1
              },
              scaleLabel: {
                display: true,
                labelString: 'Jobs count'
              }
            }]
          }
        };
      },
      getBarChartData({data = []}) {
        const labels = [];
        const datasetsData = [];

        data.forEach((item) => {
          labels.push(this.getDatasetName(item.name));
          datasetsData.push(item[`${this.dataType}CountTotal`]);
        });

        return {
          labels,
          datasets: datasetsData.length ? [{data: datasetsData, backgroundColor: blueChartColour, hoverBackgroundColor: blueChartColour, barThickness: 20}] : []
        };
      },
      getTimeChartData({data = []}) {
        let labels = [];
        const datasetsData = [];

        data.forEach((item) => {
          const entryDates = item.scheduledAt ? item.scheduledAt.map((entry) => entry.date) : [];

          labels.push(...entryDates);
        });

        labels = labels.filter((x, i, a) => a.indexOf(x) === i).sort().slice(-10);

        data.forEach((item, index) => {
          const entryValues = [];

          labels.forEach((label) => {
            if (item.scheduledAt) {
              const entry = item.scheduledAt.find((entry) => entry.date === label);

              entryValues.push(entry ? entry[`${this.dataType}Count`] : 0);
            }
          });

          datasetsData.push({
            label: this.getDatasetName(item.name),
            backgroundColor: this.colors[index % this.colors.length],
            hoverBackgroundColor: this.colors[index % this.colors.length],
            borderColor: this.colors[index % this.colors.length],
            barThickness: 16,
            borderWidth: 2,
            data: entryValues,
          });
        });

        return {
          labels: labels,
          datasets: datasetsData
        };
      },
      getOtherChartData({data = []}) {
        const labels = [];
        const datasetsData = [];
        const backgroundColor = [];

        data.forEach((item, index) => {
          labels.push(this.getDatasetName(item.name));
          datasetsData.push(item[`${this.dataType}CountTotal`]);
          backgroundColor.push(this.colors[index % this.colors.length]);
        });
        return {
          labels,
          datasets: datasetsData.length ? [{data: datasetsData, backgroundColor}] : []
        };
      },
      getChartLabels(startDate, endDate) {
        // TODO: refactor for https://github.com/Skiwo/tt-dev/issues/3552
        const chartData = [];
        const days = this.$moment(endDate).diff(this.$moment(startDate), 'month');
        for (let i = 0; i < days + 1; i += 1) {
          chartData.push(this.$moment(startDate).add(i, 'month').format('YYYY-M'));
        }
        return chartData;
      },
      getDatasetData(labels = [], data = []) {
        return labels.map((label) => {
          const filteredData = data.filter((item) => label === item.date);
          return filteredData.length
            ? filteredData.map((item) => item[`${this.dataType}Count`]).reduce((sum, currentItem) => sum + currentItem)
            : 0;
        });
      },
      getDatasetName(name = '') {
        let typeName;
        let statusName;

        if (this.isInterpretationView) {
          typeName = helpers.getJobInfo.jobType(name?.toLowerCase(), this);
          statusName = helpers.getJobInfo.jobStatus(name?.toLowerCase(), false, this);
        } else {
          typeName = helpers.getProjectInfo.subtaskType(this, name?.toLowerCase());
          statusName = helpers.getProjectInfo.projectStatus(this, name?.toLowerCase());
        }

        const formattedName = name.replace(/ -/, ' - ');

        return typeName || statusName || formattedName;
      },
      prepareDataForChart(data = [], limit = 10) {
        let primaryData = data;

        if (this.dataType === 'subtasks') {
          primaryData = primaryData.map((item) => {
            item.scheduledAt = item.createdAt;
            return item;
          });
        }

        if (primaryData.length > limit) {
          const otherItem = {
            name: this.$gettext('Other'),
            countTotal: 0,
            createdAt: [],
            scheduledAt: [],
          };
          const createdAt = [];
          const scheduledAt = [];
          const newArray = primaryData.slice(0, limit);
          for (let i = limit, lim = primaryData.length; i < lim; i += 1) {
            otherItem.countTotal += primaryData[i][`${this.dataType}CountTotal`];
            createdAt.push(primaryData[i].createdAt);
            scheduledAt.push(primaryData[i].createdAt);
          }

          const aggregatedCreatedAt = createdAt.flat().reduce((acc, item) => {
            if (acc[item.date]) {
              acc[item.date][`${this.dataType}Count`] += item[`${this.dataType}Count`];
            } else {
              acc[item.date] = {...item};
            }
            return acc;
          }, {}) || [];
          const aggregatedScheduledAt = scheduledAt.flat().reduce((acc, item) => {
            if (acc[item.date]) {
              acc[item.date][`${this.dataType}Count`] += item[`${this.dataType}Count`];
            } else {
              acc[item.date] = {...item};
            }
            return acc;
          }, {}) || [];

          otherItem.createdAt = Object.keys(aggregatedCreatedAt).map((date) => aggregatedCreatedAt[date]);
          otherItem.scheduledAt = Object.keys(aggregatedScheduledAt).map((date) => aggregatedScheduledAt[date]);

          newArray.push(otherItem);

          return newArray;
        }

        return primaryData;
      }
    }
  };
</script>

<style scoped>
  .charts-bar {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .charts-bar__total-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .charts-bar__total-value {
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  .charts-bar__total-description {
    color: #666;
  }

  .charts-bar__charts-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .charts-bar__chart {
    flex-grow: 1;
    width: 50%;
    max-width: 500px;
  }

  .charts-bar__empty-chart {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    opacity: 0.5;
  }

  @media (max-width: 1024px) {
    .charts-bar__charts-wrapper {
      flex-wrap: wrap;
    }

    .charts-bar__chart {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .charts-bar__chart {
      width: 100%;
    }
  }
</style>
